<template>
    
    <div>
        
        administrations <br>

        <Sync />

    </div>
    
</template>

<script>
    
import { inject } from '@vue/composition-api' ;
import Sync from '@/components/administrations/Sync' ;

export default {

    components: {
        Sync
    },

    setup(){
    
        const app = inject('app') ;
                
        return {
            app,
        }
    
    },

};

</script>